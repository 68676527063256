import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AppProvider } from "./contexts/AppContext.js";
import Home from "./pages/Home";
import Header from "./components/Header.js";
import Devises from "./pages/Devises.js";
import Conciergerie from "./pages/Conciergerie.js";
import Ereputation from "./pages/Ereputation.js";
import ChoixOffre from "./pages/ChoixOffre.js";
import Ethique from "./pages/Ethique.js";
import Proximite from "./pages/Proximite.js";
import Tranquilite from "./pages/Tranquilite.js";
import Particulier from "./pages/Particulier.js";
import Professionnel from "./pages/Professionnel.js";
import Tpe from "./pages/Tpe.js";
import BPartnerFidelite from "./pages/BPartnerFidelite.js";
import Activate from "./pages/Activate.js";
import AchatCarte from "./pages/AchatCarte.js";
import Parrainage from "./pages/Parrainage.js";
import DeviseFidelite from "./pages/DeviseFidelite.js";
import Virements from "./pages/Virements.js";
import Modal from "./components/Modal.js";
import BPartnerClub from "./pages/BPartnerClub.js";
import { TpeProvider } from "./contexts/TpeContext.js";
import Exclusivite from "./pages/Exclusivite.js";
import Assistant from "./pages/Assistant.js";
import Conseiller from "./pages/Conseiller.js";
import Scroll from "./components/Scroll.ts";
import Pdf from "./pages/Pdf.js";
import { useState } from "react";
import FAQ from "./pages/FAQ.js";
import { CurrencyProvider } from "./contexts/CurrencyContext.tsx";
function App() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
  return (
    <AppProvider>
      <TpeProvider>
        <CurrencyProvider>
          <Modal />
          <Router>
            <div className="App">
              <Header
                isMobileMenuOpen={isMobileMenuOpen}
                toggleMobileMenu={toggleMobileMenu}
              />
              <Scroll />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/particuliers" element={<Particulier />} />
                <Route
                  path="/professionnels"
                  element={
                    <Professionnel isMobileMenuOpen={isMobileMenuOpen} />
                  }
                />
                <Route
                  path="/bPartnerFidelite"
                  element={<BPartnerFidelite />}
                />
                <Route path="/bPartnerClub" element={<BPartnerClub />} />
                <Route path="/devises" element={<Devises />} />
                <Route path="/conciergerie" element={<Conciergerie />} />
                <Route path="/eReputation" element={<Ereputation />} />
                <Route path="/choixOffre" element={<ChoixOffre />} />
                <Route path="/ethique" element={<Ethique />} />
                <Route path="/proximite" element={<Proximite />} />
                <Route path="/exclusivite" element={<Exclusivite />} />
                <Route path="/tranquillite" element={<Tranquilite />} />
                <Route
                  path="/tpe"
                  element={<Tpe isMobileMenuOpen={isMobileMenuOpen} />}
                />
                <Route path='/faq' element={<FAQ />}/>
                <Route path="/activate" element={<Activate />} />
                <Route path="/achatCarte" element={<AchatCarte />} />
                <Route path="/parrainage" element={<Parrainage />} />
                <Route path="/deviseFidelite" element={<DeviseFidelite />} />
                <Route path="/virements" element={<Virements />} />
                <Route path="/assistant" element={<Assistant />} />
                <Route path="/conseiller" element={<Conseiller />} />
                <Route path="/pdf" element={<Pdf />} />
              </Routes>
            </div>
          </Router>
        </CurrencyProvider>
      </TpeProvider>
    </AppProvider>
  );
}

export default App;
