import React, { useState } from "react";
import Retour from "../components/Retour";
import Footer from "../components/Footer";
import SelectDevises, { DEVISES } from "../components/SelectDevises";
import { useCurrency } from "../contexts/CurrencyContext.tsx";
import { formatAmount } from "../utils/format.ts";

export default function Devises() {
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const { minimumDevises } = useCurrency();

  const onSelect = (value) => {
    const findCurrency = DEVISES.find((d) => d.code === value);
    const findMinMaxPerCurrency = minimumDevises.find((md) => md.CCY === value);
    setSelectedCurrency({
      code: findCurrency.code,
      name: findCurrency.nom,
      min: findMinMaxPerCurrency.Minimum_Consumer,
      max: findMinMaxPerCurrency.Maximum,
    });
  };

  return (
    <>
      <div className="pt-[6rem] px-[1.5rem] font-poppins lg:pt-[8rem] xx:pl-[8rem]">
        <Retour page="/" section="paiementMultiDevise" />
        <div className="lg:flex flex-row">
          <div className="text-left lg:w-[30.25rem] xx:w-[42rem] xx:flex xx:flex-col xx:justify-center mr-0 lg:mr-5">
            <p className="mt-4 uppercase font-bold text-[1.5rem] xx:text-[2rem] w-fit">
              paiement en devise
            </p>
            <h4 className="text-[1.125rem] font-semibold mt-4 xx:text-[1.5rem]">
              Des paiements internationaux fluides et maîtrisés.
            </h4>
            <p className="text-left mt-4">
              Envoyez de l’argent partout dans le monde tout en gardant le
              contrôle sur vos taux de change. Avec un compte unique, accédez à
              plus de 50 devises courantes pour simplifier vos transactions
              internationales. Plus besoin de multiplier les comptes :
              centralisez vos paiements, gagnez du temps et réduisez vos coûts.
            </p>
          </div>
          <img
            src="./assets/images/desktop-section3-home.png"
            alt="old man checking his b partner account"
            className="w-full h-[18.5rem] object-cover mt-6 lg:w-[40.25rem] lg:h-[22.81rem] lg:ml-auto xx:w-[50rem] xx:h-[25rem] xx:mr-[6rem]"
          />
        </div>
        <div className="text-left pb-24">
          <p className="mt-8 uppercase font-bold text-[1.5rem] w-fit xx:text-[2rem]">
            Liste des devises
          </p>
          <p className="">Découvrez plus de 50 devises valable dans 170 pays</p>
          <SelectDevises onSelect={onSelect} />
          {!!selectedCurrency && (
            <>
              <p className="mt-8 font-semibold mb-2">
                Limite de transaction pour {selectedCurrency.nom}{" "}
                {selectedCurrency.code}
              </p>
              <div className="flex flex-row w-full border-b p-1 lg:w-72">
                <p className="flex-1 font-semibold">Minimum</p>
                <p>
                  {formatAmount(selectedCurrency.min)} {selectedCurrency.code}
                </p>
              </div>
              {!!selectedCurrency.max && (
                <>
                  <div className="flex flex-row w-full border-b p-1 lg:w-72">
                    <p className="flex-1 font-semibold">Maximum</p>
                    {formatAmount(selectedCurrency.max)} {selectedCurrency.code}
                    *
                  </div>
                  <p className="mt-3 text-sm">
                    *pour toute demande de transfert au dessus de ce montant
                    merci de contacter nos équipes.
                  </p>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
