import React from 'react'
import Footer from '../components/Footer'
import Faq from '../components/Faq'

export default function FAQ() {
  return (
    <>
      <div className="pt-[6rem] px-[1.5rem] lg:pt-[8rem] font-poppins mb-[5rem]">
        <Faq />
      </div>
      <Footer />
    </>  
  )
}
