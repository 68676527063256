import CustomLink from "./CustomLink";

const CardParticulier = ({
  img,
  alt,
  title,
  text,
  goTo,
  from,
  contain = false,
}) => {
  return (
    <CustomLink from={from} to={goTo} className="w-full h-fit">
      <div className="lg:h-[24rem] h-[28rem] w-[12.25rem] shadow-md rounded-xl flex flex-col lg:w-[17rem] xx:w-[18rem]">
        <img
          className={`w-full h-28 rounded-t-xl ${
            !contain ? "object-cover" : "object-contain"
          } lg:h-40"`}
          src={img}
          alt={alt}
        />
        <div className="flex flex-col p-2 h-full">
          <div className="w-fit h-10 mt-1">{title}</div>
          <p className="text-left text-[0.875rem] flex-1">{text}</p>
          {goTo !== "/tpe" ? (
            <div className="flex items-center cursor-pointer">
              <img src="./assets/svg/chevron-right-red.svg" alt="Chevron" />
              <p className="text-[0.75rem] text-[#8D8F90] ml-2">
                En savoir plus
              </p>
            </div>
          ) : (
            <div className="h-8 w-auto text-white font-bold text-sm rounded bg-main-red mx-1 mb-1 flex justify-center items-center">
              Commander votre TPE
            </div>
          )}
        </div>
      </div>
    </CustomLink>
  );
};

export default CardParticulier;
