import React, { useEffect, useState } from "react";
import { useTpeContext } from "../../contexts/TpeContext";
import { getBestOffer, getCurrentUserTpeMonthlyBill } from "./functions";

export default function Step2() {
  const { state, updateState } = useTpeContext();
  const [reduction, setReduction] = useState(null);
  console.log(state)
  useEffect(() => {
    if (state.apiResult) {
      const bestOfferKey = getBestOffer(
        state.apiResult.Prestige["24 mois"],
        state.userInfo
      );
      const reductionPercentage =
        state.apiResult.Prestige["24 mois"].Economie?.[bestOfferKey];
      setReduction(reductionPercentage);
    }
  }, [state.apiResult]); // Réagit aux changements de `apiResult`
  return (
    <div className="flex flex-col">
      <p className={`text-left font-bold text-sm lg:text-base ${reduction && !reduction.includes("-") && "hidden"}`}>
        {`Sur base des informations fournies votre facture mensuelle est
        actuellement de ${getCurrentUserTpeMonthlyBill(
          state.userInfo
        )} €, nous vous proposons une réduction de ${reduction?.replace(
          "-",
          ""
        )} sur votre
        facture TPE.`}
      </p>
      <p className={`text-grey-text text-left text-sm ${reduction && !reduction.includes("-") && "hidden"}`}>
        Cette proposition se base sur les données que vous nous avez partagé.
      </p>
      <div className={`flex flex-row lg:justify-around lg:mt-8 space-x-2 lg:space-x-0 ${reduction && reduction.includes("-") && "hidden"}`}>
      Nous avons remarqué que vous avez trouvé une offre qui vous semble plus avantageuse. Cependant, nous vous invitons à découvrir en détail nos solutions, conçues pour répondre au mieux à vos besoins.
      </div>
      <div className={`flex flex-row lg:justify-around lg:mt-8 space-x-2 lg:space-x-0 ${reduction && !reduction.includes("-") && "hidden"}`}>
        <div className="flex flex-col justify-center items-center w-full lg:h-[14rem] h-40 mt-8 lg:mt-0 shadow-custom-red lg:w-[16.81rem] lg:h-[14.18rem] lg:px-4 lg:order-1">
          <p className="text-main-red lg:text-[3rem] text-2xl font-semibold">
            {/* {parseInt(
              (state.apiResult.Prestige["24 mois"].data_concurrent[
                "Coût annuel Estimé avec acquisition"
              ] -
                state.apiResult.Prestige["24 mois"][
                  getBestOffer(
                    state.apiResult.Prestige["24 mois"],
                    state.userInfo
                  )
                ]["Coût annuel Estimé avec acquisition"]) /
                12
            )}{" "}
            € */}{reduction && ((parseFloat(reduction.replace("-", "").replace("%",'')) / 100) * getCurrentUserTpeMonthlyBill(state.userInfo)).toFixed(0)} €
          </p>
          <p className="lg:text-[1.125rem] text-base lg:mt-4 mt-2">
            D'économie mensuelle
          </p>
        </div>
        <div className="flex flex-col justify-center items-center w-full lg:h-[14rem] h-40 mt-8 lg:mt-0 shadow-custom-red lg:w-[16.81rem] lg:h-[14.18rem] lg:px-4 lg:order-2">
          <p className="lg:text-[1.125rem] text-base">Soit une réduction de</p>
          <p className="text-main-red lg:text-[3rem] text-2xl font-semibold lg:mt-4 mt-2">
            {reduction && reduction.replace("-", "")}
          </p>
          <p className="lg:text-[1.125rem] text-base lg:mt-4 mt-2">
            Sur votre facture TPE
          </p>
        </div>
        <div className="flex flex-col justify-center items-center w-full lg:h-[14rem] h-40 mt-8 lg:mt-0 shadow-custom-red lg:w-[16.81rem] lg:h-[14.18rem] lg:px-4 lg:order-3">
          <p className="text-main-red lg:text-[3rem] text-2xl font-semibold">
            {/* {parseInt(
              state.apiResult.Prestige["24 mois"].data_concurrent[
                "Coût annuel Estimé avec acquisition"
              ] -
                state.apiResult.Prestige["24 mois"][
                  getBestOffer(
                    state.apiResult.Prestige["24 mois"],
                    state.userInfo
                  )
                ]["Coût annuel Estimé avec acquisition"]
            )}{" "}
            € */}{reduction && ((parseFloat(reduction.replace("-", "").replace("%",'')) / 100) * getCurrentUserTpeMonthlyBill(state.userInfo)).toFixed(0) * 12} €
          </p>
          <p className="lg:text-[1.125rem] text-base lg:mt-4 mt-2">
            D'économie annuelle
          </p>
        </div>
      </div>
      <button
        onClick={() => {
          updateState({ step: state.step + 1 });
        }}
        className="h-[3rem] bg-main-red rounded text-white font-bold mt-8 lg:ml-auto lg:w-[14rem]"
      >
        Voir les détails
      </button>
    </div>
  );
}
