import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";

interface MinimumDevise {
  CCY: string;
  Name: string;
  Minimum_Consumer: number;
  Maximum: number;
}

interface CurrencyContextProps {
  minimumDevises: MinimumDevise[];
}

const CurrencyContext = createContext<CurrencyContextProps | null>(null);

export const CurrencyProvider = ({ children }: PropsWithChildren) => {
  const [minimumDevises, setMinimumDevises] = useState<MinimumDevise[]>([]);

  const getMinimumDevises = async () => {
    try {
      const res = await fetch(
        "https://w02obst0e4.execute-api.eu-west-1.amazonaws.com/getMinimumDevises"
      );
      if (res.status === 200) {
        setMinimumDevises(await res.json());
      }
    } catch (error) {
      console.error("Error fetching minimum devises : ", error);
    }
  };

  const fetchContext = async () => {
    await Promise.all([getMinimumDevises()]);
  };

  useEffect(() => {
    fetchContext();
  }, []);

  return (
    <CurrencyContext.Provider value={{ minimumDevises }}>
      {children}
    </CurrencyContext.Provider>
  );
};

export const useCurrency = () => {
  const context = useContext(CurrencyContext);
  if (!context) {
    throw new Error("useContext must be used in Currency Provider");
  }
  return context;
};
