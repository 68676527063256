import React, { useState, Children } from 'react'

export default function ComparatifAccordeonDesktop(props) {
  // Local state to handle which question is open within *this* section
  const [openQuestionIndex, setOpenQuestionIndex] = useState(null);

  // Called when user clicks the header (instead of local 'dropped' state)
  const drop = () => {
    props.onToggle();
  };

  return (
    <div className='flex flex-col custom-shadow mt-6 '>
      {/* Header (always visible) */}
      <div
        onClick={drop}
        className='flex flex-row cursor-pointer h-[3.2556rem] items-center px-2 bg-gray-50 rounded-sm'
      >
        <p className='w-full text-left font-Poppins text-[1.125rem] font-semibold uppercase text-pink-red'>
          {props.title}
        </p>
        {props.open ? (
          <img
            className='w-[1.5rem] h-[1.5rem]'
            src='./assets/images/group-less.png'
            alt='arrow'
          />
        ) : (
          <img
            className='w-[1.5rem] h-[1.5rem]'
            src='./assets/images/group-plus.png'
            alt='arrow'
          />
        )}
      </div>

      {/* Body (questions) - only rendered if this section is open */}
      {props.open && (
        <div className='flex flex-col px-2'>
          {Children.map(props.children, (child, index) => {
            return React.cloneElement(child, {
              questionIndex: index,
              openQuestionIndex: openQuestionIndex,
              setOpenQuestionIndex: setOpenQuestionIndex
            });
          })}
        </div>
      )}
    </div>
  )
}
