import {
  Document,
  Image,
  Link,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";

const HEADERS_COL = [
  "Produit",
  "Offre TPE",
  "Durée du contrat en mois",
  "Coût d'achat",
  "Location mensuelle",
  "Maintenance",
  "Commissions en %",
  "Commissions en €",
];

export interface PdfSimulationPage {
  tpeName: string;
  duration: "12 mois" | "24 mois";
  offer: string;
  img: string;
  isPrinting: boolean;
  terminalCost: number;
  rentalCost: number;
  maintenanceCost: number;
  transactionCostPercent: number;
  transactionCostCents: number;
}

interface PdfSimulationProps {
  pages: PdfSimulationPage[];
  userInfo: {
    cost_per_transaction_cents: string;
    cost_per_transaction_percent: string;
    maintenance_cost: string;
    monthly_amount: string;
    rental_cost: string;
    terminal_cost: string;
    transaction_count: string;
  };
  products: [];
  indexOfSelectedProduct: number;
}

const PdfSimulation = ({
  pages,
  userInfo,
  products,
  indexOfSelectedProduct,
}: PdfSimulationProps) => {
  const date = new Date().toLocaleDateString("fr-FR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  const isSoftPOS = pages[0].tpeName === "B Partner Tap";
  return (
    <Document>
      <Page style={s.page}>
        <Image
          src="./assets/images/logo-b-partner-black.png"
          style={s.logo}
          fixed
        />
        <Text style={s.title} fixed>
          Votre simulation personnalisée
        </Text>

        <Text style={s.sectionTitle}>Votre simulation</Text>
        <View style={s.tableContainer}>
          <View style={s.tableHeader}>
            <Text>
              Information présentées par l'utilisateur lors de la simulation
            </Text>
          </View>
          <View style={s.rowContainer}>
            <View
              style={[s.cellContainer, s.cellContainerRightBorder, s.colorGrey]}
            >
              <Text>Volume de transactions mensuelles (€)</Text>
            </View>
            <View
              style={[s.cellContainer, s.cellContainerRightBorder, s.colorGrey]}
            >
              <Text>Nombre de transactions mensuelles</Text>
            </View>
            <View
              style={[s.cellContainer, s.cellContainerRightBorder, s.colorGrey]}
            >
              <Text>Coût par transaction (%)</Text>
            </View>
            <View style={[s.cellContainer, s.colorGrey]}>
              <Text>Coût par transaction en centimes (€)</Text>
            </View>
          </View>
          <View style={s.rowContainer}>
            <View style={[s.cellContainer, s.cellContainerRightBorder]}>
              <Text>{userInfo.monthly_amount}€</Text>
            </View>
            <View style={[s.cellContainer, s.cellContainerRightBorder]}>
              <Text>{userInfo.transaction_count}</Text>
            </View>
            <View style={[s.cellContainer, s.cellContainerRightBorder]}>
              <Text>{userInfo.cost_per_transaction_percent}%</Text>
            </View>
            <View style={s.cellContainer}>
              <Text>{userInfo.cost_per_transaction_cents}€</Text>
            </View>
          </View>
          <View style={s.rowContainer}>
            <View
              style={[s.cellContainer, s.cellContainerRightBorder, s.colorGrey]}
            >
              <Text>Coût d'achat de votre terminal (en €)</Text>
            </View>
            <View
              style={[s.cellContainer, s.cellContainerRightBorder, s.colorGrey]}
            >
              <Text>Coût de la location mensuelle du terminal (en €)</Text>
            </View>
            <View
              style={[s.cellContainer, s.cellContainerRightBorder, s.colorGrey]}
            >
              <Text>Coût de la maintenance mensuelle (en €)</Text>
            </View>
            <View style={[s.cellContainer, s.colorGrey]}>
              <Text>Date de la simulation</Text>
            </View>
          </View>
          <View style={s.rowContainer}>
            <View style={[s.cellContainer, s.cellContainerRightBorder]}>
              <Text>{userInfo.terminal_cost}€</Text>
            </View>
            <View style={[s.cellContainer, s.cellContainerRightBorder]}>
              <Text>{userInfo.rental_cost}€</Text>
            </View>
            <View style={[s.cellContainer, s.cellContainerRightBorder]}>
              <Text>{userInfo.maintenance_cost}€</Text>
            </View>
            <View style={s.cellContainer}>
              <Text>{date}</Text>
            </View>
          </View>
        </View>
        <Text style={s.sectionTitle}>Notre Offre personnalisée</Text>

        {pages.map((page, index) => {
          return (
            <View key={index} style={s.offerSectionContainer}>
              <View style={s.terminalContainer}>
                <View style={s.imageContainer}>
                  <Image src={page.img} style={s.image} />
                </View>
                <View style={s.offerDetailsContainer}>
                  <Text>
                    Produit : <Text style={s.colorRed}>{page.tpeName}</Text>
                  </Text>
                  <Text>
                    Durée de contrat :{" "}
                    <Text style={s.colorRed}>{page.duration}</Text>
                  </Text>
                  <Text>
                    Offre : <Text style={s.colorRed}>{page.offer}</Text>
                  </Text>
                </View>
              </View>
              <View style={s.tableContainer}>
                <View style={s.tableHeader}>
                  <Text>Détails de notre proposition</Text>
                </View>
                <View style={s.offerRowContainer}>
                  <Text>Fonction d'impression</Text>
                  <Text>{page.isPrinting ? "OUI" : "NON"}</Text>
                </View>
                <View style={[s.offerRowContainer, s.offerRowContainerColored]}>
                  <Text>
                    {isSoftPOS ? "Coût d’installation" : "Coût d'achat"}
                  </Text>
                  <Text>{page.terminalCost}€</Text>
                </View>
                <View style={s.offerRowContainer}>
                  <Text>Coût de location du terminal</Text>
                  <Text>{page.rentalCost}€</Text>
                </View>
                <View style={[s.offerRowContainer, s.offerRowContainerColored]}>
                  <Text>Coût de maintenance mensuelle</Text>
                  <Text>{page.maintenanceCost}€</Text>
                </View>
                <View style={s.offerRowContainer}>
                  <Text>Coût par transaction</Text>
                  <Text>
                    {page.transactionCostPercent}%
                    {page.transactionCostCents
                      ? ` + ${page.transactionCostCents}€`
                      : ""}
                  </Text>
                </View>
                <View style={[s.offerRowContainer, s.offerRowContainerColored]}>
                  <Text>Carte SIM inclue avec l'appareil</Text>
                  <Text>{isSoftPOS ? "NON" : "OUI"}</Text>
                </View>
              </View>
            </View>
          );
        })}

        <Text style={s.sectionTitle}>Toutes nos offres</Text>
        <View style={s.productHeaderContainer}>
          {HEADERS_COL.map((header, index) => {
            let bgColor = "black";
            if (index < 3) {
              bgColor = "#CC133E";
            }
            return (
              <View
                key={index}
                style={[
                  s.productHeaderColContainer,
                  { backgroundColor: bgColor },
                ]}
              >
                <Text>{header}</Text>
              </View>
            );
          })}
        </View>
        {products.map((product, index) => (
          <View
            style={[
              s.productRowContainer,
              { color: indexOfSelectedProduct === index ? "#CC133E" : "black" },
            ]}
            key={index}
          >
            {Object.keys(product).map((key) => {
              return (
                <View style={s.productRow}>
                  <Text>{product[key]}</Text>
                </View>
              );
            })}
          </View>
        ))}
        {!isSoftPOS && (
          <View style={s.asteriskContainer}>
            <Text style={s.asteriskText}>
              *Tous les clients qui souscrivent à une offre B Partner Pro ou B
              Partner Flex se voient offrir un accès B Partner Smart sans coût
              supplémentaire
            </Text>
          </View>
        )}

        <View style={s.infoSimulation}>
          <Text>
            Cette simulation est produite à titre informative, et ne constitue
            en rien une offre commerciale.
          </Text>
          <Text>
            Les éléments sont calculés sur base des informations partagées dans
            la simulation par l’utilisateur comme les montants mensuels de
            paiement, les coûts actuels…
          </Text>
          <Text>
            Pour toute information concernant nos prix nous vous invitons à
            consulter nos Conditions Tarifaires disponibles sur{" "}
            <Link>www.b-partner.com</Link>.
          </Text>
        </View>

        <View style={s.mention} fixed>
          <Text>
            B Partner SRL est une société immatriculée au registre de commerce
            en Belgique,
          </Text>
          <Text>
            sous numéro BE 1003965341. Siège social : 161 Drève Richelle, 1410
            Waterloo, Belgique.
          </Text>
          <Text>
            Téléphone : +33 1 82 83 06 80, Site web :{" "}
            <Link>www.b-partner.com</Link>
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default PdfSimulation;

const s = StyleSheet.create({
  page: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 25,
    textAlign: "center",
    fontSize: 10,
  },
  logo: { width: 100, height: 50 },
  title: {
    position: "absolute",
    top: 20,
    alignSelf: "center",
    fontSize: 8,
    color: "grey",
  },
  sectionTitle: { marginTop: 10, marginBottom: 10, fontSize: 12 },
  tableContainer: {
    border: 1,
  },
  tableHeader: {
    backgroundColor: "#CC133E",
    paddingVertical: 8,
    color: "white",
  },
  rowContainer: {
    flexDirection: "row",
    borderTop: 1,
  },
  cellContainer: {
    justifyContent: "center",
    flex: 1,
    height: 30,
    padding: 5,
  },
  cellContainerRightBorder: {
    borderRight: 1,
  },
  offerSectionContainer: {
    border: 2,
    borderColor: "#CC133E",
    padding: 10,
  },
  terminalContainer: { flexDirection: "row", alignItems: "center" },
  imageContainer: { flex: 1, alignItems: "center" },
  image: { width: 60, height: 60, objectFit: "cover" },
  offerDetailsContainer: {
    flex: 2,
    textAlign: "left",
    marginLeft: 60,
    fontSize: 12,
  },
  colorRed: {
    color: "#CC133E",
  },
  colorGrey: {
    color: "grey",
  },
  offerRowContainer: {
    padding: 5,
    flexDirection: "row",
    justifyContent: "space-between",
    borderTop: 1,
    borderColor: "grey",
  },
  offerRowContainerColored: {
    backgroundColor: "#FAFAFB",
  },
  infoSimulation: { fontSize: 8, textAlign: "center", marginTop: 30 },
  mention: {
    fontSize: 8,
    position: "absolute",
    bottom: 20,
    alignItems: "center",
    color: "grey",
    width: "calc(100% - 40px)",
  },
  productHeaderContainer: {
    flexDirection: "row",
    border: 1,
  },
  productHeaderColContainer: {
    flex: 1,
    height: 30,
    justifyContent: "center",
    textAlign: "center",
    fontSize: 8,
    color: "white",
    padding: 4,
  },
  productRowContainer: {
    flexDirection: "row",
    borderBottom: 1,
    borderRight: 1,
    borderLeft: 1,
  },
  productRow: {
    flex: 1,
    height: 20,
    justifyContent: "center",
    textAlign: "center",
    fontSize: 8,
  },
  asteriskContainer: {
    borderBottom: 1,
    borderRight: 1,
    borderLeft: 1,
    height: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  asteriskText: { fontSize: 8, color: "grey" },
});
