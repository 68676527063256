import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppContext } from "../contexts/AppContext";
import Faq from "./Faq";
import HubspotForm from "./HubspotForm.tsx";
import useIsMobile from "../hooks/useIsMobile.js";
import { constants } from "../constants/index.ts";
export default function Footer() {
  const { pathname } = useLocation();
  const isMobile = useIsMobile();
  const { updateContext } = useAppContext();
  const openFaq = () => {
    updateContext("modalOpen", true);
    updateContext("modalElement", <Faq />);
  };
  const openForm = () => {
    updateContext("modalOpen", true);
    updateContext(
      "modalElement",
      <HubspotForm formId={constants.hubspot.contactFormId} />
    );
  };
  return (
    <div className="h-fit w-full bg-black footer text-white px-[1.5rem] xx:px-[4rem]">
      <div className="border-b-2 lg:py-8 py-5">
        <div className=" flex flex-col md:flex-row w-full md:justify-between text-left">
          <p className="text-[1.5rem] lg:text-[2rem] tracking-widest">
            Rejoignez <span className="text-main-red">B Partner</span>
          </p>
          <div className="flex flex-row items-center">
            <p className="mr-4">Suivez nous sur les réseaux sociaux</p>
            <Link
              target="_blank"
              to="https://www.linkedin.com/company/bpartnerbanking/posts/?feedView=all"
            >
              <img alt="linkedin icon" src="./assets/svg/linkedin.svg" />
            </Link>
          </div>
        </div>
        <div className="text-left text-xs font-dmSans lg:flex lg:flex-row lg:mt-[1.5rem] lg:justify-between">
          <div className="space-y-3 lg:w-[40rem]">
            <p>
              Le compte B Partner Premium, Premium Business, Prestige ou
              Prestige Business, est un compte portefeuille de monnaie
              électronique aussi appelé "e-money wallet"
            </p>
            {/* <br /> */}
            <p>
              La carte de paiement est émise par Paynovate SA en vertu d’une
              licence de Visa International Inc. Paynovate SA est autorisée par
              la Banque National de Belgique à émettre de la monnaie
              électronique et à fournir les services de paiement associés (réf
              0506.763.929), dans le cadre de la réglementation applicable aux
              établissements de Monnaie Électronique. Paynovate SA est un membre
              principal de Visa.
            </p>
          </div>
          <div className="space-y-0 mt-3 lg:w-[23rem] lg:mt-0 lg:space-y-0">
            <p className="">
              B Partner SRL est une société immatriculée au registre de commerce
              en Belgique, sous le numéro BE 1003965341.{" "}
            </p>
            <p>Siège social : 161 Drève Richelle, 1410 Waterloo, Belgique</p>
            <p>Téléphone +33 1 82 83 06 80,</p>
            <p>Site web : www.b-partner.com</p>
          </div>
        </div>
        <div className="flex flex-row text-black space-x-4 mt-2 md:justify-end justify-center">
          <a
            className="bg-white rounded-lg md:px-3 p-2 md:py-2 flex flex-row cursor-pointer items-center"
            href="https://play.google.com/store/apps/details?id=com.anonymous.BPartnerMobileApp"
            target="_blank"
          >
            <img
              src="./assets/svg/google-play-logo.svg"
              className="md:w-9 md:h-9 w-7 h-7 mr-1"
            />
            <div className="flex flex-col items-start">
              <p className="md:text-sm text-xs">Téléchargez la sur</p>
              <p className="font-semibold md:text-xl text-md">Google Play</p>
            </div>
          </a>
          <a
            className="bg-white rounded-lg md:px-3 p-2 md:py-2 flex flex-row cursor-pointer items-center"
            href="https://apps.apple.com/us/app/b-partner/id6739487821"
            target="_blank"
          >
            <img
              src="./assets/svg/app-store-logo.svg"
              className="md:w-9 md:h-9 w-7 h-7 mr-1"
            />
            <div className="flex flex-col items-start">
              <p className="md:text-sm text-xs">Téléchargez la sur</p>
              <p className="font-semibold md:text-xl text-md">App Store</p>
            </div>
          </a>
        </div>
      </div>
      <div className="lg:py-8 py-5 flex flex-col space-y-4 lg:space-y-0 lg:flex lg:flex-row lg:justify-between lg:items-center">
        {!isMobile && (
          <img
            alt="logo b partner"
            src="./assets/images/logo-b-partner.png"
            className="w-[4rem] h-[2.43rem]"
          />
        )}
        <p className="font-poppins text-xs text-left">
          © B Partner 2024 - ISO 14001, ISO 27001, ISO 9001
        </p>
        <ul className="flex flex-row flex-wrap text-xs text-left lg:space-x-6">
          <li className="w-1/3 lg:w-fit">
            <Link to="/">Accueil</Link>
          </li>
          <li className="w-1/3 lg:w-fit">
            <Link to="/pdf?doc=cgu.pdf" target="_blank">
              CGU
            </Link>
          </li>
          <li className="w-1/3 lg:w-fit">
            <Link
              to="/pdf?doc=b-partner_cgu-services-complementaires.pdf"
              target="_blank"
            >
              CGU Services Complémentaires
            </Link>
          </li>
          {pathname === "/professionnels" && (
            <li className="w-1/3 lg:w-fit">
              <Link
                to="/pdf?doc=b-partner_conditions-tarifaires-pro.pdf"
                target="_blank"
              >
                Conditions Tarifaires
              </Link>
            </li>
          )}
          {pathname === "/particuliers" && (
            <li className="w-1/3 lg:w-fit">
              <Link
                to="/pdf?doc=b-partner_conditions-tarifaires-particuliers.pdf"
                target="_blank"
              >
                Conditions Tarifaires
              </Link>
            </li>
          )}
          <li className="w-1/3 lg:w-fit">
            <Link to="/pdf?doc=mLegaleBpartner.pdf" target="_blank">
              Mentions légales
            </Link>
          </li>
          <li className="w-1/3 lg:w-fit">
            <Link to="/pdf?doc=confidentialite.pdf" target="_blank">
              Confidentialité
            </Link>
          </li>
            <li className="w-1/3 lg:w-fit cursor-pointer">
          <Link to='/faq' target="_blank">
              FAQ
          </Link>
            </li>
          <li onClick={openForm} className="w-1/3 lg:w-fit cursor-pointer">
            Contact
          </li>
        </ul>
      </div>
    </div>
  );
}
