import React from 'react'

export default function Question(props) {
  // Instead of local state, we check if "we" are the currently open question
  const isOpen = props.openQuestionIndex === props.questionIndex;

  const handleOpen = () => {
    // Toggle logic: if we are open, close; otherwise open
    if (isOpen) {
      props.setOpenQuestionIndex(null);
    } else {
      props.setOpenQuestionIndex(props.questionIndex);
    }
  };

  return (
    <div
      className={`flex flex-col border-b pt-2 pb-4 ${
        props.rep === '' ? 'hidden' : ''
      }`}
    >
      {/* Question header row */}
      <div className='flex flex-row items-center'>
        <p className='text-left font-Poppins font-bold text-[0.95rem] mr-2'>
          {props.q}
        </p>
        <img
          className={
            !isOpen
              ? 'w-[1.25rem] h-[1.25rem] cursor-pointer ml-auto mb-auto mt-[2px]'
              : 'w-[1rem] h-[1rem] cursor-pointer ml-auto mb-auto mt-[2px]'
          }
          onClick={handleOpen}
          alt='voir plus'
          src={isOpen ? './assets/images/croix.png' : './assets/images/plus.png'}
        />
      </div>

      {/* The answer content, visible only if isOpen */}
      <div
        className={`${
          isOpen ? '' : 'hidden'
        } text-left font-Poppins font-normal text-[0.75rem] mt-3`}
      >
        {props.rep}
      </div>
    </div>
  )
}
