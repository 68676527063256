import React from "react";
import useIsMobile from "../hooks/useIsMobile";
import SectionParticulier from "../components/SectionParticulier";
import Carrousel from "../components/Carroussel";
import CardParticulier from "../components/CardParticulier";
import Comparatif from "../components/Comparatif";
import Footer from "../components/Footer";
import CustomLink from "../components/CustomLink";
export default function Particulier() {
  const isMobile = useIsMobile();
  return (
    <div
      className={`flex flex-col font-poppins ${
        !isMobile ? "h-auto overflow-hidden" : ""
      }`}
    >
      <div>
        <div className="relative h-screen">
          <img
            className="absolute left-0 w-full h-[100%] object-cover z-0"
            src="./assets/images/fondParticulier.png"
          />

          <div className="relative z-10 flex px-[1.5rem] lg:px-[3rem] xx:px-[6rem] flex-col pt-[25%] lg:pt-[8%] xx:pt-[5%] text-white h-screen justify-start ">
            <p className="mt-8 text-[1.5rem] font-semibold leading-[2.45rem] text-left lg:text-[2rem] lg:w-[32.0625rem] lg:mt-[5rem] xx:text-[2.75rem] xx:w-[45.0625rem] xx:leading-[3.5rem]">
              Simplifiez votre quotidien grâce à un support d’exception.
            </p>
            <p className="font-dmSans leading-[1.6875rem] font-medium text-left lg:mt-[1.25rem] lg:w-[32.0625rem] xx:w[45.0625rem] xx:text-[1.125rem] mt-8">
              Gérez vos transactions en toute simplicité, centralisez vos
              comptes et dépenses en devises pour optimiser vos coûts. Nous
              proposons des solutions parfaitement adaptées à vos objectifs,
              pour vous offrir une gestion fluide et efficace au quotidien.
            </p>
            <CustomLink
              from="/particuliers"
              className="relative w-fit h-fit mt-[2rem] mb-[2rem] lg:w-[14.5rem]"
              to="/particuliers"
              scrollTo={"comparatifPart"}
            >
              <button className="w-[13.875rem] h-[3rem] bg-white text-black font-dmSans font-bold rounded">
                <div className="w-fit flex flex-row mx-auto">
                  <span>Découvrir nos offres</span>
                  <img
                    alt="chevron right"
                    src="./assets/svg/chevron-right-red.svg"
                    className="ml-2"
                  />
                </div>
              </button>
            </CustomLink>
          </div>
        </div>
        <SectionParticulier
          id="bpClubSection"
          title={
            <>
              notre offre <span className="text-main-red">prestige</span>
            </>
          }
          lign="des services qui vous mènent vers l'excellence"
          subTitle="L’excellence au service de vos ambitions."
          text="Un service exclusif pour ceux qui exigent le meilleur. L’offre Prestige vous donne accès à un compte pensé pour une clientèle exigeante, avec des services personnalisés pour simplifier votre quotidien. Profitez d’une relation privilégiée avec des conseillers dédiés, de solutions sur-mesure et d’un confort inégalé à chaque étape. 

Gagnez du temps, accédez à l’essentiel et laissez-nous vous accompagner dans la réalisation de vos projets."
          imgName="img1-particulier.png"
          textBtn="Souscrire"
          altImg="woman checking her account"
          align="left"
          goTo="https://app.b-partner.com/register"
          blank={true}
        />
        <div className="flex flex-col font-poppins px-[1.5rem] lg:justify-center mt-14 lg:mt-20">
          <p className="uppercase text-main-red tracking-wider font-semibold lg:tracking-widest xx:text-[1.25rem] md:text-center text-left">
            Des services d'exception
          </p>
          <p className="font-bold text-[1.25rem] mt-2 lg:text-[1.875rem] uppercase md:text-center text-left">
            Dans votre offre Prestige
          </p>
          <div className="xx:w-fit xx:mx-auto">
            <Carrousel id="valeurs">
              <CardParticulier
                img="./assets/images/card1-car2.jpeg"
                alt={"reception"}
                title={
                  <p className="font-bold text-[1rem]">Assistant personnel</p>
                }
                text={
                  "Simplifiez votre quotidien avec un service sur-mesure. Organisation de vacances, réservations exclusives, et accompagnement personnalisé pour répondre à vos besoins avec confort et discrétion."
                }
                goTo={"/assistant"}
                from="/particuliers#valeurs"
              />
              <CardParticulier
                img="./assets/images/card2-car2.png"
                alt={"party"}
                title={<p className="font-bold text-[1rem]">B Partner Club</p>}
                text={
                  "Rejoignez un cercle exclusif dédié à une clientèle d’exception. Accédez à des privilèges uniques, des événements prestigieux et des opportunités rares, alliant réseau d’affaires et art de vivre d’exception."
                }
                goTo={"/bPartnerClub"}
                from="/particuliers#valeurs"
              />
              <CardParticulier
                img="./assets/images/newimg2-bpf.png"
                alt={"woman"}
                title={
                  <p className="font-bold text-[1rem]">B Partner Fidélité</p>
                }
                text={
                  "Cumulez des points à chaque transaction et accédez à des avantages exclusifs. Bénéficiez de réductions, services sur-mesure, et expériences luxueuses adaptés à votre style de vie."
                }
                goTo={"/bPartnerFidelite"}
                from="/particuliers#valeurs"
              />
              <CardParticulier
                img="./assets/images/section3-home.png"
                alt={"man on laptop"}
                title={
                  <p className="font-bold text-[1rem]">Paiements en devise</p>
                }
                text={
                  "Simplifiez vos transactions internationales avec un compte unique offrant l’accès à plus de 50 devises. Gérez vos paiements mondiaux en toute maîtrise, tout en réduisant vos coûts."
                }
                goTo={"/devises"}
                from="/particuliers#valeurs"
              />
              <CardParticulier
                img="./assets/images/card5-car2.png"
                alt={"man on laptop"}
                title={
                  <p className="font-bold text-[1rem]">Conseiller financier</p>
                }
                text={
                  "Bénéficiez d’un accompagnement sur-mesure pour optimiser votre patrimoine, structurer vos investissements et planifier votre avenir en toute confidentialité."
                }
                goTo={"/conseiller"}
                from="/particuliers#valeurs"
              />
            </Carrousel>
          </div>
        </div>
        <SectionParticulier
          id="bpClubSection"
          title={
            <>
              notre offre <span className="text-main-red">premium</span>
            </>
          }
          lign="l'efficacité chaque jour"
          subTitle="Une solution complète pour une gestion fluide au quotidien."
          text="Optimisez votre gestion financière avec l’Offre Premium, conçue pour s’adapter à votre rythme de vie. Profitez d’avantages exclusifs, d’un accompagnement personnalisé et d’outils innovants pour gérer vos finances en toute simplicité. Cette offre combine efficacité et flexibilité, tout en vous offrant une relation client dédiée pour un quotidien sans contraintes."
          imgName="img2-particulier.png"
          textBtn="Souscrire"
          altImg="woman checking her account"
          align="right"
          goTo="https://app.b-partner.com/register"
          blank={true}
        />
        <div className="flex flex-col font-poppins px-[1.5rem] lg:justify-center mt-14 lg:mt-20">
          <p className="uppercase text-main-red tracking-wider font-semibold lg:tracking-widest xx:text-[1.25rem] text-left md:text-center">
            Des services adaptés
          </p>
          <p className="font-bold text-[1.25rem] mt-2 lg:text-[1.875rem] uppercase text-left md:text-center">
            Dans votre offre Premium
          </p>
          <div className="xx:w-fit xx:mx-auto">
            <Carrousel id="valeurs2">
              <CardParticulier
                img="./assets/images/card1-car1.png"
                alt={"man crossed arms"}
                title={
                  <p className="font-bold text-[1rem]">Service conciergerie</p>
                }
                text={
                  "Un service sur-mesure pour vos besoins professionnels et personnels : organisation de voyages, réservations gastronomiques, services quotidiens et accès privilégié à la culture et au bien-être."
                }
                goTo={"/conciergerie"}
                from="/particuliers#valeurs2"
              />
              <CardParticulier
                img="./assets/images/newimg2-bpf.png"
                alt={"woman"}
                title={
                  <p className="font-bold text-[1rem]">B Partner Fidélité</p>
                }
                text={
                  "Cumulez des points à chaque transaction et accédez à des avantages exclusifs. Bénéficiez de réductions, services sur-mesure, et expériences luxueuses adaptés à votre style de vie."
                }
                goTo={"/bPartnerFidelite"}
                from="/particuliers#valeurs2"
              />
              <CardParticulier
                img="./assets/images/card3-car1.png"
                alt={"man on laptop"}
                title={
                  <p className="font-bold text-[1rem]">Paiements en devise</p>
                }
                text={
                  "Simplifiez vos transactions internationales avec un compte unique offrant l’accès à plus de 50 devises. Gérez vos paiements mondiaux en toute maîtrise, tout en réduisant vos coûts."
                }
                goTo={"/devises"}
                from="/particuliers#valeurs2"
              />
              <CardParticulier
                img="./assets/images/card4-car1.png"
                alt={"man on laptop"}
                title={
                  <p className="font-bold text-[1rem]">Conseiller financier</p>
                }
                text={
                  "Bénéficiez d’un accompagnement sur-mesure pour optimiser votre patrimoine, structurer vos investissements et planifier votre avenir en toute confidentialité."
                }
                goTo={"/conseiller"}
                from="/particuliers#valeurs2"
              />
            </Carrousel>
          </div>
        </div>
        <Comparatif />
        <Footer />
      </div>
    </div>
  );
}
