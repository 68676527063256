import React from "react";
import useIsMobile from "../hooks/useIsMobile";
import SectionParticulier from "../components/SectionParticulier";
import Carrousel from "../components/Carroussel";
import CardParticulier from "../components/CardParticulier";
import ComparatifPro from "../components/ComparatifPro";
import Footer from "../components/Footer";
import CustomLink from "../components/CustomLink";
import SimulateTpeCost from "../components/SimulateTpeCost";
export default function Professionnel({ isMobileMenuOpen }) {
  const isMobile = useIsMobile();
  return (
    <div className="flex flex-col font-poppins">
      <div>
        <div className="container-snap relative h-screen bg-black">
          {/*           {!isMobileMenuOpen && (
            <SimulateTpeCost to="/tpe" text="VOIR LES TERMINAUX DE PAIEMENT" />
          )} */}
          <img
            className="absolute right-0 bottom-0 lg:w-auto xx:w-1/2 z-0"
            src="./assets/images/cardHome.png"
          />

          <div className="relative z-10 flex px-[1.5rem] lg:px-[3rem] xx:px-[6rem] flex-col pt-[34%] lg:pt-[8%] xx:pt-[5%] text-white h-screen justify-start">
            <p className="text-[1.5rem] font-semibold leading-[2.45rem] text-left lg:text-[2rem] lg:w-[32.0625rem] lg:mt-[5rem] xx:text-[2.75rem] xx:w-[45.0625rem] xx:leading-[3.5rem]">
              Accélérez votre développement avec une gestion fluide et
              optimisée.
            </p>
            <p className="font-dmSans mt-8 leading-[1.6875rem] font-medium text-left lg:mt-[1.25rem] lg:w-[32.0625rem] xx:w[45.0625rem] xx:text-[1.125rem]">
              Centralisez vos comptes et dépenses en devises,  dans le respect
              des normes ISO en vigueur, simplifiez vos transactions et réduisez
              vos coûts pour libérer tout le potentiel de votre entreprise.
            </p>
            <CustomLink
              from="/professionnels"
              className="relative w-fit h-fit mt-[2rem] mb-[2rem] lg:w-[14.5rem]"
              to="/professionnels"
              scrollTo={"comparatifPro"}
            >
              <button className="w-[13.875rem] h-[3rem] bg-white text-black font-dmSans font-bold rounded">
                <div className="w-fit flex flex-row mx-auto">
                  <span>Découvrir nos offres</span>
                  <img
                    alt="chevron right"
                    src="./assets/svg/chevron-right-red.svg"
                    className="ml-2"
                  />
                </div>
              </button>
            </CustomLink>
          </div>
        </div>
        <SectionParticulier
          id="bpClubSection"
          title={
            <>
              notre offre{" "}
              <span className="text-main-red">prestige business</span>
            </>
          }
          lign="L’accompagnement qui propulse votre entreprise vers DE NOUVEAUX HORIZONS "
          subTitle="Optimisez votre temps, maîtrisez votre croissance."
          text="Libérez-vous des contraintes financières et consacrez-vous à l’essentiel : développer votre activité. L’Offre Prestige Business propose des solutions sur-mesure conçues pour simplifier votre gestion financière et soutenir votre expansion. 

Gagnez du temps grâce à des outils performants et bénéficiez d’un accompagnement personnalisé pour anticiper chaque étape de votre croissance. Avec des solutions fluides et efficaces, votre entreprise se concentre sur son cœur de métier, en toute sérénité."
          imgName="img-pro-1.png"
          textBtn="Souscrire"
          altImg="woman checking her account"
          align="left"
          goTo="https://app.b-partner.com/register"
          blank={true}
        />
        <div className="flex flex-col font-poppins px-[1.5rem] lg:justify-center mt-14 lg:mt-20">
          <p className="uppercase text-main-red tracking-wider font-semibold lg:tracking-widest xx:text-[1.25rem] text-left md:text-center">
            DES SERVICES D’EXCEPTION pour votre entreprise
          </p>
          <p className="font-bold text-[1.25rem] mt-2 lg:text-[1.875rem] uppercase text-left md:text-center">
            Inclus dans l’offre Prestige{" "}
          </p>
          <div className="xx:w-fit xx:mx-auto">
            <Carrousel id="valeurs">
              <CardParticulier
                img="./assets/images/section4-home.png"
                alt={"reception"}
                title={
                  <p className="font-bold text-sm lg:text-base text-left">
                    Terminaux de paiement
                  </p>
                }
                text={
                  "Louez des terminaux adaptés à vos besoins, avec ou sans imprimante et carte SIM. Profitez d’une solution mobile, fiable et tout-en-un pour rester compétitif et protéger vos marges."
                }
                goTo={"/tpe"}
                from="/professionnels#valeurs"
                contain={true}
              />
              <CardParticulier
                img="./assets/images/card2-car3.png"
                alt={"party"}
                title={
                  <p className="font-bold text-sm lg:text-base text-left">
                    B Partner Club
                  </p>
                }
                text={
                  "Rejoignez un cercle exclusif dédié à une clientèle d’exception. Accédez à des privilèges uniques, des événements prestigieux et des opportunités rares, alliant réseau d’affaires et art de vivre d’exception."
                }
                goTo={"/bPartnerClub"}
                from="/professionnels#valeurs"
              />
              <CardParticulier
                img="./assets/images/newimg2-bpf.png"
                alt={"woman"}
                title={
                  <p className="font-bold text-sm lg:text-base text-left">
                    B Partner Fidélité
                  </p>
                }
                text={
                  "Cumulez des points à chaque transaction et accédez à des avantages exclusifs. Bénéficiez de réductions, services sur-mesure, et expériences luxueuses adaptés à votre style de vie."
                }
                goTo={"/bPartnerFidelite"}
                from="/professionnels#valeurs"
              />
              <CardParticulier
                img="./assets/images/section3-home.png"
                alt={"man on laptop"}
                title={
                  <p className="font-bold text-sm lg:text-base text-left">
                    Paiements en devise
                  </p>
                }
                text={
                  "Simplifiez vos transactions internationales avec un compte unique offrant l’accès à plus de 50 devises. Gérez vos paiements mondiaux en toute maîtrise, tout en réduisant vos coûts."
                }
                goTo={"/devises"}
                from="/professionnels#valeurs"
              />
              <CardParticulier
                img="./assets/images/card1-car2.jpeg"
                alt={"man on laptop"}
                title={
                  <p className="font-bold text-sm lg:text-base text-left">
                    Assistant personnel
                  </p>
                }
                text={
                  "Simplifiez votre quotidien avec un service sur-mesure. Organisation de vacances, réservations exclusives, et accompagnement personnalisé pour répondre à vos besoins avec confort et discrétion."
                }
                goTo={"/assistant"}
                from="/professionnels#valeurs"
              />
              <CardParticulier
                img="./assets/images/section5-home.png"
                alt={"man on laptop"}
                title={
                  <p className="font-bold text-sm lg:text-base text-left">
                    Service e-réputation
                  </p>
                }
                text={
                  "Un service exclusif pour protéger votre image et votre réputation sur internet et sur les réseaux sociaux. Un bilan de votre réputation vous est offert une fois votre compte activé "
                }
                goTo={"/eReputation"}
                from="/professionnels#valeurs"
              />
            </Carrousel>
          </div>
        </div>
        <SectionParticulier
          id="bpClubSection"
          title={
            <>
              notre offre{" "}
              <span className="text-main-red">PREMIUM BUSINESS</span>
            </>
          }
          lign="Une solution complète pour gérer vos opérations au quotidien."
          subTitle="Des services simples et performants pour votre activité."
          text="Facilitez la gestion de vos comptes grâce à l’Offre Premium Business Conçue pour répondre aux besoins des professionnels, cette solution inclut des paiements SEPA, instantanés et internationaux, tout en assurant une visibilité totale sur vos opérations financières. Des services personnalisés et intuitifs vous permettent de gérer vos transactions avec rapidité et efficacité, pour que vous puissiez vous concentrer sur ce qui compte vraiment : le développement de votre entreprise."
          imgName="pro-2.png"
          textBtn="Souscrire"
          altImg="woman checking her account"
          align="right"
          goTo="https://app.b-partner.com/register"
          blank={true}
        />
        <div className="flex flex-col font-poppins px-[1.5rem] lg:justify-center mt-14 lg:mt-20">
          <p className="uppercase text-main-red tracking-wider font-semibold lg:tracking-widest xx:text-[1.25rem] text-left md:text-center">
            Des services adaptés
          </p>
          <p className="font-bold text-[1.25rem] mt-2  lg:text-[1.875rem] uppercase text-left md:text-center">
            Inclus dans l’offre Premium{" "}
          </p>
          <div className="xx:w-fit xx:mx-auto">
            <Carrousel id="valeurs2">
              <CardParticulier
                img="./assets/images/section4-home.png"
                alt={"reception"}
                title={
                  <p className="font-bold text-sm lg:text-base text-left">
                    Terminaux de paiement
                  </p>
                }
                text={
                  "Louez des terminaux adaptés à vos besoins, avec ou sans imprimante et carte SIM. Profitez d’une solution mobile, fiable et tout-en-un pour rester compétitif et protéger vos marges."
                }
                goTo={"/tpe"}
                from="/professionnels#valeurs2"
                contain
              />
              <CardParticulier
                img="./assets/images/newimg2-bpf.png"
                alt={"woman"}
                title={
                  <p className="font-bold text-sm lg:text-base text-left">
                    B Partner Fidélité
                  </p>
                }
                text={
                  "Ce programme de Fidélité est conçu pour remercier nos clients les plus fidèles. Cumulez des points de Fidélités et choisissez la manière dont vous souhaitez les dépenser par la suite."
                }
                goTo={"/bPartnerFidelite"}
                from="/professionnels#valeurs2"
              />
              <CardParticulier
                img="./assets/images/card3-car1.png"
                alt={"man on laptop"}
                title={
                  <p className="font-bold text-sm lg:text-base text-left">
                    Paiements en devise
                  </p>
                }
                text={
                  "Réalisez vos paiements en devise directement sur votre Application B Partner, et payer dans plus de 50 devises et vers plus d’une centaine de pays à frais réduits. Suivez vos paiements en temps-réel sur votre téléphone ou votre ordinateur. "
                }
                goTo={"/devises"}
                from="/professionnels#valeurs2"
              />
              <CardParticulier
                img="./assets/images/conciergerie.png"
                alt={"man crossed arms"}
                title={
                  <p className="font-bold text-sm lg:text-base text-left">
                    Services conciergerie
                  </p>
                }
                text={
                  "Nos concierges B Partner seront à vos écoute afin de répondre à vos sollicitions. Pour l’organisation de vos prochaines vacances ou pour réserver une table dans un restaurant prisé, faites appel à votre Conciergerie B Partner"
                }
                goTo={"/conciergerie"}
                from="/professionnels#valeurs2"
              />
              <CardParticulier
                img="./assets/images/cfinancier.png"
                alt={"party"}
                title={
                  <p className="font-bold text-sm lg:text-base text-left">
                    Conseiller financier
                  </p>
                }
                text={
                  "Nos experts financiers et patrimoniaux sont à votre écoute pour établir un bilan personnalisé de vos projets. Que ce soit en immobilier, en épargne financière ou dans d’autres types de placements, nos conseillers sont là pour vous accompagner."
                }
                goTo={"/conseiller"}
                from="/professionnels#valeurs2"
              />
            </Carrousel>
          </div>
        </div>
        <ComparatifPro />
        <Footer />
      </div>
    </div>
  );
}
